.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.navbar > .container,
.App {
    max-width: 1440px;
}

.App {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto 0;
    padding: 0 .75rem;
}

.custom-file {
    position: relative;
}

.custom-file input[type="file"] {
    visibility: hidden;
}

.custom-file button {
    position: absolute;
    left: 0;
    top: 0;
}

.custom-file button label {
    display: block;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

.btn.btn-custom {
    background: inherit;
    background-color: rgba(153, 51, 51, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    padding: 8px 50px;
    font-size: 13px;
}

.btn.btn-outline-custom {
    background: inherit;
    background-color: transparent;
    border: 1px solid rgba(153, 51, 51, 1) !important;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(153, 51, 51, 1);
    padding: 7px 50px;
    font-size: 13px;
}

.input-group .btn.btn-custom {
    padding: 8px 20px;
    border: 1px solid rgba(153, 51, 51, 1);
}

.link-custom {
    color: rgba(153, 51, 51, 1);
    font-size: 13px;
}

.link-custom:hover {
    color: #7a2929;
}

.link-custom.disabled {
    opacity: .5;
    cursor: not-allowed;
}

.btn.btn-custom.inherit,
.custom-default.inherit {
    padding: 8px 15px;
}

.btn.btn-outline-custom.inherit {
    padding: 7px 15px;
}

.btn.btn-custom:hover,
.btn.btn-outline-custom:hover,
.btn.btn-outline-custom:active,
.btn.btn-outline-custom:focus {
    background-color: #7a2929 !important;
    color: #fff;
}

.btn.btn-outline-custom:focus,
.btn.btn-custom:focus {
    box-shadow: 0 0 0 0.25rem rgb(153 51 51 / 40%) !important;
}

.upload-page .btn.btn-custom {
    padding: 0;
    width: 230px;
    height: 40px;
}

.upload-page .btn {
    margin-right: 25px;
    margin-top: -5px;
}

.custom-default {
    background-color: transparent;
    border: 1px solid #993333;
    border-radius: 5px;
    padding: 8px 30px;
    font-size: 13px;
    color: #993333;
}

.possible_match {
    outline: 2px dashed #b201fb;
    outline-offset: 4px;
}

.custom-default[disabled] {
    border-color: #e3d9d9;
    color: #e3d9d9;
}

a.custom-default {
    text-decoration: none;
}

a.custom-default:hover {
    color: #7a2929;
}

.App hr {
    opacity: 1;
}

.App h4 {
    font-weight: bold;
}

.App .selected-file strong {
    display: inline-block;
    padding-left: 20px;
}

.App .contest select,
select.custom-select,
.App .contest input[type="text"],
.App .contest input[type="number"],
.custom-input-text {
    border: 1px solid #333;
    padding: 3px;
    background-color: #fff;
    font-size: 13px;
    height: 30px;
    border-radius: 3px;
}

.App .contest select[disabled] {
    background-color: #eee;
}

.App .contest input[type="text"],
.App .contest input[type="number"] {
    /*margin-right: 1rem;*/
}

.App .contest-separator {
    height: 70px;
}

.App .contest .cell,
.single-cell {
    border: 1px solid #797979;
    padding: 0 10px;
    margin-bottom: -1px;
    min-height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.App .contest .content {
    padding-right: 5px;
}

.one-line.single-cell {
    height: 30px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.App .green_indicator,
.App .green_indicator a {
    color: #20c70a;
}

.App .yellow_indicator {
    color: #b201fb;
}

.App .red_indicator {
    color: #ff001d;
}

.App .edited {
    font-style: italic;
}

.App .contest .empty-cell {
    height: 40px;
}

.App .contest .w-200p {
    width: 200px;
}

.App .edit-cell,
.App .save-edit-cell {
    /*position: absolute;*/
    right: 35px;
    top: 10px;
}

.App .save-edit-cell {
    position: absolute;
    right: 0;
    background-color: #fff;
    top: 5px;
    padding: 5px 10px 5px 20px;
}

.App .cell.with-action {
    /*padding-right: 50px;*/
}

.App .cell.cell-property-vineyard:not(.with-action) {
    display: grid;
    grid-template-columns: auto 1fr auto;
}

.App .cell.edited {
    font-style: italic;
}

.App .cell.editing-cell .content {
    display: none !important;
}

.App .restore-history-cell {
    /*position: absolute;*/
    right: 60px;
    top: 10px;
}
.App .row-actions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.App .editable-field {
    position: absolute;
    left: 10px;
    right: 35px;
    z-index: 5;
}

.App .cell.edited .react-select__menu,
.App .cell.edited .react-select__single-value,
.App .cell.edited .react-select__input-container,
.App .cell.edited .react-select__placeholder {
    font-style: normal !important;
}

.w-100p {
    width: 100px;
}

.w-145p {
    width: 145px;
}

.w-185p {
    width: 185px;
}

.w-157p {
    width: 157px;
}

.h-39 {
    height: 39px;
}

.App .contest > div:last-child .cell {
    border-bottom: 1px solid #333;
}

.App .contest .db-fields {
    height: 39px;
    padding: 4px 0;
}

.App .contest .db-fields > select {
    float: left;
}

.App .contest .column-name {
    padding-top: 10px;
    font-weight: bold;
}

.App .contest .column-name .synonym {
    position: absolute;
    margin-right: -20px;
}

.App .contest .column-value.property-vineyard .cell,
.App .contest .column-value.property-name .cell {
    padding-right: 55px;
}

.single-cell.property-vineyard {
    padding-right: 65px;
}

.App .contest .cell-property-award {
    height: 100%;
    border-bottom: 0 !important;
    padding: 10px;
}

.App .contest .column-value .search-google-mini,
.single-cell .search-google-mini {
    position: absolute;
    right: 45px;
}

.App .contest .db-fields:hover .add-input-fields,
.App .contest .db-fields:hover .remove-input-fields {
    visibility: visible;
}

.App .contest .add-input-fields,
.App .contest .remove-input-fields,
.additional-button {
    width: 34px;
    height: 30px;
    font-size: 20px;
    border-radius: 3px;
    border: 1px solid #333;
    margin-left: 5px;
    line-height: 25px;
}

.App .contest .add-input-fields,
.App .contest .remove-input-fields {
    visibility: hidden;
}

.App .contest .add-input-fields:hover,
.App .contest .remove-input-fields:hover,
.additional-button:hover {
    background-color: #04a804;
}

.App .contest .db-fields > div {
    height: 40px;
}

.App .contest input[type="text"] {
    height: 30px;
    border: 1px solid #333;
    padding: 0 10px;
}

.App select.form-select {
    max-width: initial;
}

.App .contest .property-save {
    color: #00ccff;
}

.App .contest .property-skip {
    color: #ccc;
}

.App .contest .external-wine-image {
    position: relative;
    display: flex;
    width: 80px;
    justify-content: center;
    overflow: hidden;
}

.App .contest .external-wine-image::before {
    content: "";
    position: absolute;
    background: no-repeat url("./assets/images/external-link.webp") center;
    width: 10px;
    height: 10px;
    top: 10px;
    right:0;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

ul.matches-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 30px;
}

ul.matches-pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(121, 121, 121, 1);
    font-size: 12px;
    margin-right: 9px;
}

ul.matches-pagination li a {
    height: 29px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #333;
}

ul.matches-pagination li.active {
    background-color: rgba(204, 204, 204, 1);
}

.App .contest .container-check-fields {
    padding: 0;
}

.App .contest .row-check > div:first-child {
    border-right: 1px solid #797979;
}

.App .contest .row-check.property-award,
.App .contest .row-check.property-image {
    height: 100%;
}

.App .contest .check {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.similar-regions h6 {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 6px 0;
    font-weight: 600;
}

.custom-input-text:disabled {
    background-color: #eee;
}

.modal .alert.error {
    background-color: #993333;
    color: #fff;
}

.files-listing small {
    color: #b4b4b4;
}

.search-google .google-logo {
    width: 50px;
    margin-left: 5px;
}

.adp-tooltip--component {
    text-transform: none;
    max-width: 700px;
}

.adp-tooltip--wine {
    text-transform: none;
    max-width: 400px;
}

.header a.back {
    top: 5px;
    position: absolute;
    right: 15px;
}

.wine-profile .wine-image img {
    width: 150px;
}

.wine-profile .wine-content ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.wine-profile .wine-content ul.two-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
}

.wine-profile .wine-content ul li span.title {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.wine-profile .wine-content ul li {
    padding: 10px 0;
    word-break: break-word;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 100%;
}

.auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
}

.dropdown-default button {
    background-color: #fff;
    color: rgba(0, 0, 0, .55);
    border-color: transparent;
    display: flex;
    align-items: center;
}

.dropdown-default button:hover,
.dropdown-default button:focus,
.dropdown-default button:active,
.dropdown-default button.dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
    color: rgba(0, 0, 0, .70) !important;
    box-shadow: none !important;
}

.copy-clipboard {
    position: absolute;
    right: 20px;
    margin-top: -5px;
}

.App .saved-icon {
    font-size: 23px;
    color: #26db26;
}

.select-all-title {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
}

.select-all-title h6 {
    text-align: center;
    font-size: 15px;
}

.preloader {
    background-image: url("./assets/images/spinner-1s-20px.gif");
    background-repeat: no-repeat;
    background-position: center;
}

.matches-pagination li.preloader a {
    color: #fff !important;
}

.msg-data-saved {
    font-size: 14px;
    position: absolute;
    display: inline-block;
    margin-top: 23px;
    margin-left: 15px;
}

.msg-data-saved svg {
    font-size: 24px;
    color: #08be08;
}

.App .cell.cell-property-image {
    height: 100px;
}

.App .cell.cell-property-image img {
    padding-top: 10px;
    padding-bottom: 10px;
}

.App .cell.cell-property-image .remove-image {
    position: absolute;
    top: 10px;
    right: 10px;
}

.App .btn-outline-info,
.App .btn-outline-success {
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 13px;
}

.App .btn-outline-success:hover,
.App .btn-outline-success:active {
    background-color: #993333;
    border-color: #993333;
}

.App .btn-outline-info.active {
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
}

.navbar .nav-link.static {
    color: rgba(0, 0, 0, .7) !important;
}

.w-17pct {
    width: 17%;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
    z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
    z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
    z-index: 1000;
}

.grape-item {
    display: flex;
    align-items: center;
    padding: 4px 5px 0 0;
}

.grape-item:last-child {
    padding-bottom: 5px;
}

.App .contest .cell-property-grape_type {
    padding: 10px;
    height: 100%;
    border-bottom: 0 !important;
}

.cell-property-grape_type.editing-cell .row-actions {
    flex-direction: column;
    gap: 0;
}

.property-grape_type {
    height: 100%;
}

.grape-item .action {
    cursor: pointer;
}

.grape-item .action .add-btn {

}

.grape-item .action .remove-btn {
    color: #993333;
}

.grape-item .grape-calculate-percent {
    width: 37px;
}

.winery-comments {
    position: relative;
}

.winery-comments .winery-has-comment {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #bfc901;
    border-radius: 50%;
    right: -4px;
    border: 1px solid #fff;
}

.reload-matching-arrow {
    color: rgba(153, 51, 51, 1);
    cursor: pointer;
    margin-left: 10px;
}

.selected-wines {
    height: 140px;
    background-color: #f0f0f0;
    padding: 10px 15px 5px;
    margin-bottom: 10px;
    border-radius: 3px;
}

.selected-wines legend {
    font-size: 1.25rem;
}

.selected-wines ol {
    overflow: auto;
    height: 87px;
}

.selected-wines ol li {
    font-size: 14px;
    padding: 1px 0;
}

.highlight-diff {
    background-color: #a2ff3147;
}

.MergeCompare .property-vintage_id,
.MergeCompare .cell-property-vintage_id {
    margin-top: 3rem;
}

.diff-values {
    color: #20c70a;
}

input.filter-input {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    padding: 0 5px;
}

.h-200 {
    height: 200px;
}
.h-270 {
    height: 270px;
}

.empty-winery-image {
    background-color: #eeeef3;
    width: 320px;
    height: 320px;
    border-radius: 5px;
    color: #ceb9b9;
    text-transform: uppercase;
    font-size: 12px;
}

fieldset.styled {
    padding: 20px;
    border: 1px solid #333;
}

.regions-page .selected-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px;
}

.rule-user {
    white-space: nowrap;
    text-transform: capitalize;
}

.rule-user svg {
    position: relative;
    top: -2px;
}

.actions-preloader {
    display: inline-block;
    width: 20px;
    height: 34px;
    margin-left: 10px;
}

.word-break-word {
    word-break: break-word;
}

.photo-status-empty svg {
    color: #ff3838;
}

.photo-status-bad svg {
    color: #cb832b;
}

.photo-status-broken svg {
    color: #a1031d;
}

.row-gray {
    color: #9ea7ad;
}

.forget-1 {
    background-color: #b2ffca;
}

.vivino-row-unconfirmed a,
.vivino-row-unconfirmed a:hover {
    color: #56f000;
}

.vivino-row-valid a,
.vivino-row-valid a:hover {

}

.vivino-row-same a,
.vivino-row-same a:hover {
    color: #9ea7ad;
}

button.link-edit,
button.link-save-edit {
    border: 0;
    background: transparent;
}

button.link-edit svg,
button.link-save-edit svg {
    height: 12px;
    color: #212529;
}

.image-gallery {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: auto;
    max-height: 280px;
}

.image-gallery li {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 1px solid #eee;
    vertical-align: top;
    text-align: center;
    margin: 8px;
    position: relative;
}

.image-gallery li img {
    max-width: 100%;
    height: auto;
    max-height: 60px;
}

.image-gallery li .status {
    position: absolute;
    top: -5px;
    left: 1px;
}

.image-gallery li .status svg {
    width: 12px;
}

.image-gallery li .status.new svg {
    color: gray;
}

.image-gallery li .status.failed svg {
    color: #d90000;
}

.image-gallery li .status.ok svg {
    color: #05b905;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    width: 80%;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-btn-wrapper .btn {
    background-color: #993333;
    color: #fff;
    line-height: 34px;
    border-radius: 3px;
    border: 0;
    width: 100%;
    height: 46px;
}

.preview-multiple-images {
    display: flex;
}

.preview-multiple-images .actions {
    width: 20%;
}

.preview-multiple-images .clean {
    background: #ff4d4f;
    border: 0;
    height: 46px;
    color: #fff;
    border-radius: 3px;
    padding: 1px 15px;
    width: 50%;
    margin-left: 10px;
    transition: ease all .3s;
}

.preview-multiple-images .clean:hover {
    background-color: #c93435;
}

.preview-multiple-images .upload {
    color: #fff;
    background: #1890ff;
    border: 0;
    border-radius: 3px;
    padding: 1px 15px;
    height: 46px;
    width: 50%;
    margin-left: 10px;
}

.preview-multiple-images .upload:hover {
    background-color: #1179d7;
}

.preview-multiple-images .clean[disabled] {
    background: #ff4d4f;
    opacity: 0.5;
}

.preview-multiple-images .upload[disabled] {
    opacity: 0.5;
    background: #1890ff;
}

.word-break-word {
    word-break: break-word;
}

.edit-field-box {
    position: relative;
}

.edit-field-box.loading:after {
    content: "";
    position: absolute;
    background: center url("./assets/images/spinner-1s-20px.gif") no-repeat;
    width: 20px;
    height: 20px;
    right: 5px;
    bottom: 5px;
}

.edit-field-box.loading input {
    color: #afafaf;
}

table.table-fixed thead,
table.table-fixed tfoot,
.fixed-thead thead {
    position: sticky;
}

table.table-fixed thead,
.fixed-thead thead {
    inset-block-start: 0; /* "top" */
    background-color: #fff;
    padding: 3px 10px;
}

table.table-fixed tfoot {
    inset-block-end: 0; /* "bottom" */
}

.table-fixed.custom tbody td:nth-child(1),
.table-fixed thead > tr > th:nth-child(1) {
    width: 6%;
}

.table-fixed.custom tbody td:nth-child(2),
.table-fixed thead > tr > th:nth-child(2) {
    width: 15%;
}

.table-fixed.custom tbody td:nth-child(3),
.table-fixed thead > tr > th:nth-child(3) {
    width: 25%;
}

.table-fixed.custom tbody td:nth-child(5),
.table-fixed thead > tr > th:nth-child(5) {
    width: 6%;
}

.table-fixed.custom tbody td:nth-child(6),
.table-fixed thead > tr > th:nth-child(6) {
    width: 6%;
}

.table-fixed.custom tbody td:nth-child(7),
.table-fixed thead > tr > th:nth-child(7) {
    width: 14%;
}

.table-fixed.custom tbody td:nth-child(8),
.table-fixed thead > tr > th:nth-child(8) {
    width: 5%;
}

.table-fixed.custom tbody td:nth-child(9),
.table-fixed thead > tr > th:nth-child(9) {
    width: 14%;
}

.table-fixed.custom tbody td:nth-child(10),
.table-fixed thead > tr > th:nth-child(10) {
    width: 6%;
}

.table-bordered {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #dee2e6;
    width: 100%;
}
.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-bordered th,
.table-bordered td {
    padding: 5px;
    vertical-align: top;
    font-size: 13px;
}

.table-bordered thead th {
    vertical-align: bottom;
}

.table-bordered tbody + tbody {
    border-top: 2px solid #dee2e6;
}

.upload-vivino input {
    display: none;
}

.upload-vivino {
    border: 1px solid rgba(153, 51, 51, 1);
    border-radius: 5px;
    display: inline-block;
    padding: 7px 50px;
    cursor: pointer;
    font-size: 13px;
    color: rgba(153, 51, 51, 1);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.upload-vivino:hover {
    background-color: #7a2929;
    color: #fff;
}

#ReactSimpleImageViewer {
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

table.hover-vertical {
    overflow: hidden;
    display: inline-block;
}

table.hover-vertical td,
table.hover-vertical th {
    position: relative;
}

table.hover-vertical td:hover::before {
    background-color: #eee;
    content: '';
    height: 100%;
    left: -5000px;
    position: absolute;
    top: 0;
    width: 10000px;
    z-index: -2;
}

table.hover-vertical td:hover::after {
    background-color: #ffa;
    content: '';
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
    z-index: -1;
}

.btn-chat {
    background-color: transparent;
    border: 0;
    position: relative;
}

.btn-chat.with-messages::before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #bfc901;
    border-radius: 50%;
    right: 0;
    border: 1px solid #fff;
}


.chat-list {
    list-style: none;
    margin: -15px;
    padding: 15px 30px;
    background: #F2F5F8;
    overflow-y: scroll;
    height: 400px;
}

.chat-list li {
    position: relative;
}

.chat-list li .author {
    padding-left: 15px;
}

.chat-list li.personal::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.chat-list li.personal .author {
    padding-left: 0;
    padding-right: 15px;
}

.chat-list li .author span {
    color: #a8aab1;
    padding-left: 10px;
}

.chat-list li .author::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #86BB71;
    margin-top: 7px;
    left: 0;
}

.chat-list li.personal .author::before {
    right: 0;
    left: auto;
    background-color: #94C2ED;
}

.chat-list li.personal .author span {
    padding-left: 0;
    padding-right: 10px;
}

.chat-list li .message {
    color: white;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 90%;
    position: relative;
    background: #86BB71;
}

.chat-list li .message:after {
    bottom: 100%;
    left: 7%;
    border: 10px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #86BB71;
    margin-left: -10px;
}

.chat-list li.personal .author {
    text-align: right;
}

.chat-list li.personal .message {
    background: #94C2ED;
    float: right;
}

.chat-list li.personal .message:after {
    right: 5%;
    left: auto;
    border-bottom-color: #94C2ED;
}

.chat-list li.personal .btn-remove-message {
    left: 8.5%;
    right: auto;
}

.chat-list li .btn-remove-message {
    background-color: transparent;
    border: 0;
    position: absolute;
    margin-top: 7px;
    top: 20px;
    left: auto;
    right: 8.5%;
}

.chat-list li .btn-remove-message svg {
    background-color: #fff;
    border-radius: 50%;
}

.chat-list li .btn-remove-message svg path {
    fill: #c02424;
}

.chat-list li.empty-list {
    text-align: center;
    font-size: 12px;
    color: #7e7e7e;
}

.chat-send-area {
    background: #F2F5F8;
    margin: -15px;
    padding: 15px 15px 0;
    position: relative;
}

.chat-send-area::before {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    background-color: #fff;
    top: 0;
}

textarea.chat-message {
    width: 100%;
    border: none;
    padding: 10px 20px;
    font: 14px/22px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: none;
}

.pagination.inline {
    display: inline-flex;
}

.pagination-goto {
    display: inline-flex;
    align-items: center;
}

.page-item.active .page-link {
    z-index: 0 !important;
}

.App .contest .pagination-goto input {
    margin-left: 10px;
    border: 1px solid #dee2e6;
    width: 80px;
    padding: 1rem 0.75rem;
}

.App .contest .pagination-goto button {
    color: #0d6efd;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #dee2e6;
    padding: 0.25rem 0.75rem;
}

.App .contest .pagination-goto button:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.form-group.loading {
    position: relative;
}

.form-group.loading:after {
    content: "";
    position: absolute;
    background: center url("./assets/images/1488.gif") no-repeat;
    width: 20px;
    height: 20px;
    right: 5px;
    bottom: 5px;
}

.form-group.loading.disabled:after {
    background: center url("./assets/images/1488_disabled.gif") no-repeat;
}

.form-group.loading.select:after {
    bottom: 9px;
    right: 50px;
    background: center url("./assets/images/1488.gif") no-repeat;
}

.wineries-merge-in ul {
    padding: 0;
    margin: 0 15px;
    list-style: none;
}

.wineries-merge-in ul li {
    padding: 5px 0;
}

.wineries-merge-in ul li label {
    cursor: pointer;
}

.wineries-merge-in .selected {
    font-weight: bold;
}

.wineries-merge-in .merge-fields .form-group {
    padding: 5px 0;
}

.search-google-mini img {
    height: 16px;
}

.preview-logo a {
    display: block;
    border: 1px solid #333;
    border-radius: 4px;

    padding: 2px 8px 6px;
    color: #333;
}

.preview-logo a[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
}

.highlight {
    background-color: yellow;
    font-weight: bold;
}

.global-search table thead tr th:nth-child(1) {
    width: 5%;
}

.global-search table thead tr th:nth-child(2) {
    width: 55%;
}

.global-search table thead tr th:nth-child(3) {
    width: 17%;
}

.global-search table thead tr th:nth-child(4) {
    width: 18%;
}

.global-search table thead tr th:nth-child(5) {
     width: 10%;
 }

.text-danger {
    color: #dc3545;
}

.user-acronym {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 14px;
    cursor: default;
}

.local-link {
    display: inline-block;
    border-bottom: 1px dotted #0d6efd;
    color: #0d6efd;
    cursor: pointer;
}

.modal-competitions-list td {
    padding: 5px;
}

.modal-competitions-body {
    max-height: 500px;
    overflow: auto;
}

.modal-grapes-list .actions {
    cursor: pointer;
    vertical-align: middle;
}

.modal-grapes-list .actions .disabled {
    opacity: 0.3;
}

.modal-grapes-list .edited {
    font-style: italic;
}

.upload-container {
    margin-top: 20px;
}

.upload-container .separator {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #a7a7a7;
}

#form-file-upload {
    height: 10rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    border: 0;
    background: rgba(153, 51, 51, 1);
    border-radius: 3px;
    padding: 7px 20px 9px ;
    color: #fff;
    font-size: 12px;
    transition: all 0.3s ease;
}

.upload-button:hover {
    background-color: #7a2929 !important;
    color: #fff;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.competition-preview-logo {
    background-color: #eeeef3;
    height: 192px;
    border-radius: 5px;
    color: #ceb9b9;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.competition-preview-logo img {
    max-width: 100%;
    max-height: 100%;
    height: inherit;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.editorCompetition {
    max-height: 195px;
    min-height: 195px;
    border: 1px solid #333;
    border-radius: 3px;
    padding: 0 10px;
}

.wine-image {
    text-align: center;
}

.footer {
    min-height: 56px;
}

.footer .test-server-line {
    position: fixed;
    top: -5px;
    height: 261px;
    width: 235px;
    right: 0;
    text-align: center;
    background: url("./assets/images/warning_line_badge.webp");
    line-height: 56px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    margin-top: 56px;
    pointer-events: none;
}

.footer .test-server-line span {
    transform: rotate(49deg);
    display: inline-block;
    margin-top: 108px;
    margin-left: 51px;
}

.spinner-border-xs {
    width: 0.82rem !important;
    height: 0.82rem !important;
    border-width: 0.1em !important;
}

.has-unread {
    background-color: #0d6efd;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
}

.automatch-table th:nth-child(1),
.automatch-table td:nth-child(7) {
    width: 18%;
}
.automatch-table th:last-child {
    width: 190px;
}

.App .right-click-menu {
    display: none;
    position: absolute;
    z-index: 20;
    background-color: rgba(13, 13, 14, 0.95);
    color: #fff;
    border-radius: 5px;
    padding: 8px 0;
}

.App .right-click-menu .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
}

.App .right-click-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
}

.App .right-click-menu ul li {
    padding: 5px 24px;
    cursor: pointer;
}

.App .right-click-menu ul li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.App .row-menu {
    position: absolute;
    right: 7px;
}

.App .row-menu .row-menu-icon {
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.App .row-menu .row-menu-icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.App .row-menu .row-menu-content {
    display: none;
    position: absolute;
    background-color: rgba(13, 13, 14, 0.95);
    border-radius: 5px;
    z-index: 20;
}

.App .row-menu .row-menu-content.open {
    display: block;
}

.App .row-menu .row-menu-content ul {
    list-style: none;
    margin: 0;
    color: #fff;
    padding: 8px 0;
    position: relative;
    z-index: 10;
}

.App .row-menu .row-menu-content ul li {
    padding: 5px 16px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.App .row-menu .row-menu-content ul li img {
    padding-right: 10px;
}

.App .row-menu .row-menu-content ul li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.App .row-menu .row-menu-content .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
}

.App .panel {
    margin: 0 auto;
    -webkit-box-shadow: 0 10px 29px -16px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 10px 29px -16px rgba(0, 0, 0, 0.12);
    box-shadow: 0 10px 29px -16px rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 5px;
}

.App .panel .panel-header {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 2.5rem 1rem 1.5rem;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
}

.App .panel .panel-header:hover {
    background-color: #f9f9f9;
}

.App .panel .panel-header:after {
    content: "+";
    position: absolute;
    right: 1.5rem;
    top: 0.7rem;
    font-size: 26px;
}

.App .panel.open .panel-header:after {
    content: "-";
}

.App .panel .panel-body {
    padding: 0;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease 0s, opacity 0.3s ease 0s;
}

.App .panel.open .panel-body {
    max-height: 1000px;
    height: auto;
    opacity: 1;
}

.App .panel .panel-body .list-row span:first-child {
    width: 40%;
    display: inline-block;
}

.App .panel .panel-body .list-row span:last-child {
    color: #808080;
}

.App .gwmr-calculator .awards-list{
    margin: 0 0 15px;
    padding: 0;
    list-style: none;
}

.App .gwmr-calculator .awards-list li:last-child .panel .panel-header {
    border-bottom: 0;
}

.App .gwmr-calculator .result-title {
    background-color: #fafafa;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: normal;
}

.App .with-help-button {
    position: relative;
}

.App .with-help-button .custom-input-text {
    padding-right: 35px;
}

.App .with-help-button .help-button {
    border: 0;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 2px;
    transition: opacity 0.3s ease;
}

.App .with-help-button .help-button:hover {
    opacity: .7;
}
