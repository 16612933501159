* {
    line-height: initial;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'IBM Plex Sans Arabic Bold 700', sans-serif;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    justify-content: inherit;
}

span.input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
}

span.input-group-text {
    padding: 0;
}

div.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
}

div.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
}

div.row div.col-sm-2 {
    text-align: right;
    padding: 0;
}

select.form-select {
    max-width: 140px;
    margin-right: 1rem;
    height: 30px;
    font-size: 14px;
}

div.col-sm-5 {
    display: flex;
    align-items: initial;
}

div.input-group {
    display: flex;
    align-items: center;
}

.field1 {
    max-width: 140px;
    max-height: 30px;
}

.max-width140 {
    max-width: 140px;
    margin-right: 1rem;
}
